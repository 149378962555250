import React, { useState, useEffect } from 'react';
import { getPaymentForm } from '../../services/payment';
import { toast } from 'react-toastify';
import { Loader } from '@components';
import { json } from 'stream/consumers';
const WithoutSavingCard = (props: any) => {
  const [ispayLoading, setPayLoading] = useState(false);
  const [paymentFormUrl, setPaymentFormUrl] = useState('');
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setPayLoading(true);
    if (props.updateManuallyPrice > 0) {
      getPaymentData();
    }
  }, [props.updateManuallyPrice]);

  const getPaymentData = () => {
    const param = { 'PaymentMode': 1, updateManuallyPrice: props.updateManuallyPrice };
    getPaymentForm(param).then(function (res) {
      if (res && typeof res.data !== "undefined") {
        const resp = res.data;
        if (resp.Acknowledge == 1 && resp.FullMessage != "") {
          setPaymentFormUrl(resp.FullMessage);
        } else {
          toast.error('Error occured on payment endpoint.please try again.');
          return false;
        }
      }
      setPayLoading(false);
    }).catch(err => {
      console.log(err);
      setPayLoading(false);
      return false;
    });
  }

  const startPayment = () => {
    setLoading(true);
  }
  window.startPayment = startPayment;

  function handlePaymentErrors(data: any) {
    // Clear all existing toast messages  
    setLoading(false);
    toast.dismiss();
    const message = getErrorMessage(data.errorCode);
    if (message != 0) {
      toast.error(message);
    } else {
      toast.error("Please enter correct card details");
    }
  }
  window.handlePaymentErrors = handlePaymentErrors;


  function completePayment(data: any) {
    setLoading(false);
    if (data.uID) {
      props.setPaymentUid(data.uID);
      props.setShowWithoutSavingComponents(false);
      props.setShowPaymentSuccessBlk(true);
    }
  }
  window.completePayment = completePayment;

  const hpfReady = () => {
  }
  window.hpfReady = hpfReady;

  function cancelPayment() {
    toast.dismiss();
    toast.error('Payment Cancelled');
  }
  window.cancelPayment = cancelPayment;

  // For Credit card and ECP payment
  const whatsThis = (data: any) => {
    if (data == "cvv") {
      toast.error('The 3 numbers on the back of your card.');
    }
    if (data == "routing") {
      toast.error('Routing number can be up to 9 digits.');
    }
    if (data == "account") {
      toast.error('Account number can be between 3 and 17 digits.');
    }
  }
  window.whatsThis = whatsThis;

  const getErrorMessage = (errorCode: any) => {

    const errorMessages = [
      {
        key: 100,
        message: "Merchant Identifier left blank or not valid. The transaction was not processed."
      },
      {
        key: 110,
        message: "Merchant Identifier left blank or not valid. The transaction was not processed."
      },
      {
        key: 200,
        message: "Name not present."
      },
      {
        key: 300,
        message: "Amount not specified or invalid value entered."
      },
      {
        key: 310,
        message: "Credit card number left blank or did not pass Mod10."
      },
      {
        key: 315,
        message: "Credit card number did not pass Mod10."
      },
      {
        key: 320,
        message: "Credit card type left blank or invalid."
      },
      {
        key: 330,
        message: "Expiration month left blank."
      },
      {
        key: 340,
        message: "Expiration year left blank."
      },
      {
        key: 350,
        message: "CVV2 field submitted but does not match the card."
      },
      {
        key: 355,
        message: "CVV2 required but not present."
      },
      {
        key: 357,
        message: "An invalid character was entered, such as a letter in a numeric field."
      },
      {
        key: 360,
        message: "Payment declined by financial institution, or some other error has occurred (returned from Orbital), such as an invalid message type."
      },
      {
        key: 365,
        message: "The max_user_retries limit has been reached."
      },
      {
        key: 370,
        message: "Expiration date invalid."
      },
      {
        key: 400,
        message: "Transaction tracer value does not match."
      },
      {
        key: 841,
        message: "Account number has failed a prefix check and is invalid for the merchant account "
      }
    ];
    // errorCode =  errorCode.trim().replace(/,\s*$/, "");
    const error = errorMessages.find((err) => err.key === Number(errorCode));
    if (error) {
      return error.message;
    } else {
      return 0;
    }

    // const error = errorMessages.find((err) => err.key === errorCode);
    // alert("error"+error);
    // alert("message"+error.message);
    // return error ? error.message : 0;
  };
  window.getErrorMessage = getErrorMessage;



  return (
    <>
      <div className='not_saved_card_blk order_info_blk'>
        <h3 className='blk_title'>Credit Card Details</h3>
        <div id='secureFrameWrapper'>
          <div id='secureFrameWrapper'>
            {(ispayLoading) ?
              <div style={{ height: "350px" }} id="loadingpayment"
                className="d-flex w-100 justify-content-center align-items-center"
              >
                Loading payment form....
              </div>
              :
              <iframe id="secureFrame" className="secureFrame credit_card" style={{ border: "0", height: "385px", width: "100%" }} src={paymentFormUrl}>
              </iframe>
            }
          </div>
        </div>
        <div className="authorization_wrap">
          <div className="authorization_inner">
            <p>Authorized By</p>
            <ul>
              <li><img src="img/chase_payment.jpg" alt="Chase Payment" /></li>
              <li><img src="img/master_card.jpg" alt="Master Card" /></li>
              <li><img src="img/visa_card.jpg" alt="Visa" /></li>
              <li><img src="img/discover_icon.jpg" alt="Discover" /></li>
              <li><img src="img/american_express.jpg" alt="American Express" /></li>
            </ul>
          </div>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </>
  );
};

export default WithoutSavingCard;
