import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Loader } from '@components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import { getAccountDetails, sendEmailSms } from '../../services/payment';
import SuccessMsgMdl from './modals/SuccessMsgMdl';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useLocation } from 'react-router-dom';
const Step1 = (props: any) => {
    const todayDate = new Date();
    const [modalShow, setModalShow] = React.useState(false);
    const [sampleInvoiceModalShow, setSampleInvoiceModalShow] = React.useState(false);
    const [isLoading, setLoading] = useState(false);
    const [showKeyemailSMSinput, setShowKeyemailSMSinput] = useState(false);
    const [showEmailSMSinput, setShowEmailSMSinput] = useState("1");

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    // Get individual query parameters
    const ledgerNumber = searchParams.get('ledger');
    const zipCode = searchParams.get('zip');
    useEffect(() => {
        // Check if the values are neither null nor empty
        if (ledgerNumber && zipCode) {
            const param = {
                "LedgerNumber": ledgerNumber,
                'ZipCode': zipCode
            };
            getAccountDetailsApi(param);
        }
    }, [ledgerNumber, zipCode]); // Add ledgerNumber and zipCode as dependencies 

    const [initialValues, setInitialValues] = useState({ LedgerNumber: '', emailSMS: '', contactMethod: '1', Email: '', Phone: '', ZipCode: '' });
    const [resp, setResp] = useState([]);

    const handleCheckType = (evt: any) => {
        setShowEmailSMSinput("1");
        if (evt.target.checked) {
            setShowKeyemailSMSinput(true);
            handleChange({ target: { name: 'emailSMS', value: evt.target.value } });
            handleChange({ target: { name: 'LedgerNumber', value: '' } });
            handleChange({ target: { name: 'ZipCode', value: '' } });
            handleChange({ target: { name: 'contactMethod', value: "1" } });
        } else {
            setShowKeyemailSMSinput(false);
            handleChange({ target: { name: 'emailSMS', value: '' } });
            handleChange({ target: { name: 'Phone', value: '' } });
            handleChange({ target: { name: 'Email', value: '' } });
        }
    }

    const handleCheckinptType = (evt: any) => {
        setShowEmailSMSinput(evt.target.value);
        if (evt.target.value == '1') {
            handleChange({ target: { name: 'Phone', value: '' } });
        } else {
            handleChange({ target: { name: 'Email', value: '' } });
        }
        handleChange({ target: { name: 'contactMethod', value: evt.target.value } });
    }


    let validationShape = {};

    if (!showKeyemailSMSinput) {
        validationShape.LedgerNumber = Yup.number().required('Account Number is required');
        validationShape.ZipCode = Yup.number().required('Zip Code is required');
    } else {
        if (showEmailSMSinput == "1") {
            validationShape.Email = Yup.string().required('Email is required').email();
        } else if (showEmailSMSinput == "2") {
            validationShape.Phone = Yup.string().required('Phone is required');
        }
    }

    const { handleChange, values, handleSubmit, touched, errors } = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object(validationShape),
        onSubmit: (values) => {
            getData(values);
        },
    });

    const getData = (values: any) => {
        if (values.Phone != '') {
            values.Phone = values.Phone.replace(/\D/g, "");
        }

        if (values.emailSMS == '1') {

            setLoading(true);
            sendEmailSms(values).then(function (res: any) {
                if (typeof res.data !== 'undefined') {
                    const resp = res.data;
                    if (resp.Acknowledge == 1) {
                        let respData: any = {
                            email: values.Email,
                            phone: values.Phone,
                            type: values.contactMethod
                        };
                        // send info to success model popup 
                        setResp(respData);
                        setModalShow(true);
                    } else {
                        let msg = 'No data available for this email';
                        if (values.contactMethod == '2') {
                            msg = 'No data available for this phone number.'
                        }
                        toast.error(msg);
                    }
                }
                setLoading(false);
            }).catch(err => {
                console.log(err);
                setLoading(false);
                toast.error('Error occured.please try again.');
            });
        } else {
            getAccountDetailsApi(values);
        }
    }

    const getAccountDetailsApi = (values) => {
        setLoading(true);
        getAccountDetails(values).then(function (res: any) {
            setLoading(false);
            if (typeof res.data !== 'undefined') {
                const resp = res.data;

                if (resp.Acknowledge == 1) {
                    // Set customer info data to pass into step2 for payment form
                    let getAccountDetailsModel = (resp.OutputModel) ? resp.OutputModel : [];
                    let cardDetails = (resp.CardDetails.length > 0) ? resp.CardDetails : [];

                    if (getAccountDetailsModel) {
                        let responseParams = {
                            CustomerName: (getAccountDetailsModel.CustomerName) ? getAccountDetailsModel.CustomerName : "",
                            Address: (getAccountDetailsModel.Address) ? getAccountDetailsModel.Address : "",
                            PhoneNumber: (getAccountDetailsModel.PhoneNumber) ? getAccountDetailsModel.PhoneNumber : "",
                            EmailAddress: (getAccountDetailsModel.EmailAddress) ? getAccountDetailsModel.EmailAddress : "",
                            DivisionCode: (getAccountDetailsModel.DivisionCode) ? getAccountDetailsModel.DivisionCode : "",
                            Amount: (getAccountDetailsModel.LedgerBalance) ? getAccountDetailsModel.LedgerBalance : "",
                            LedgerNumber: (getAccountDetailsModel.LedgerNumber) ? getAccountDetailsModel.LedgerNumber : "",
                            InVoiceDate: (getAccountDetailsModel.InVoiceDate) ? getAccountDetailsModel.InVoiceDate : "",
                            InVoiceTotal: (getAccountDetailsModel.InVoiceTotal) ? getAccountDetailsModel.InVoiceTotal : "",
                            InVoiceNumber: (getAccountDetailsModel.InVoiceNumber) ? getAccountDetailsModel.InVoiceNumber : "",
                            ZipCode: (values.ZipCode) ? values.ZipCode : ""
                        }

                        if (cardDetails && responseParams) {
                            responseParams.paymentMethods = cardDetails;
                        }

                        props.setCustomerData(responseParams);
                        navigateToStep();
                    } else {
                        toast.error('Error occured.please try again.');
                    }

                } else {
                    toast.error((resp.Message) ? resp.Message : 'Ledger Number and Zip Code Not Matching.please try again with correct one.');
                }
            }
        }).catch(err => {
            console.log(err);
            setLoading(false);
            toast.error('Error occured.please try again.');
        });
    }

    const navigateToStep = () => {
        props.gotoStep(2);
    }

    const handleCloseSMSMdl = () => {
        setModalShow(false);
        setShowKeyemailSMSinput(false);
        setShowEmailSMSinput("1");
        handleChange({ target: { name: 'emailSMS', value: '' } });
        handleChange({ target: { name: 'contactMethod', value: "1" } });
        handleChange({ target: { name: 'Email', value: "" } });
        handleChange({ target: { name: 'Phone', value: "" } });
    }

    return (
        <>
            <section className="payment_link_blk">
                <div className="payment_link_content">
                    <form onSubmit={handleSubmit}>
                        <div className='account_number_blk'>
                            <h5 className='blk_title'>Please provide your Account Number and ZIP Code to proceed with payment.</h5>
                            {/* Account Number input */}
                            <Row>
                                <Col xs="12" sm="6">
                                    <div className="form-group">
                                        <label htmlFor="LedgerNumber">Account Number</label>
                                        <Form.Control
                                            id="LedgerNumber"
                                            name="LedgerNumber"
                                            type="text"
                                            onChange={handleChange}
                                            placeholder="Enter Account Number"
                                            value={values.LedgerNumber}
                                            readOnly={(showKeyemailSMSinput) ? true : false}
                                            isValid={touched.LedgerNumber && !errors.LedgerNumber}
                                            isInvalid={touched.LedgerNumber && !!errors.LedgerNumber}
                                        />
                                        {touched.LedgerNumber && errors.LedgerNumber ? (
                                            <Form.Control.Feedback type="invalid">
                                                {errors.LedgerNumber}
                                            </Form.Control.Feedback>
                                        ) : ''}
                                        <span className='sample_invoice_blk'>
                                            <span className='small'>Account number can be found on top of your invoice.</span>
                                            <Button className='invoice_btn' onClick={() => setSampleInvoiceModalShow(true)} title='Sample Invoice'></Button>
                                        </span>
                                    </div>
                                </Col>
                                <Col xs="12" sm="6">
                                    <div className="form-group">
                                        <label htmlFor="ZipCode">ZIP Code</label>
                                        <Form.Control
                                            id="ZipCode"
                                            name="ZipCode"
                                            type="text"
                                            onChange={handleChange}
                                            placeholder="Enter Zip Code"
                                            value={values.ZipCode}
                                            maxLength={5}
                                            readOnly={(showKeyemailSMSinput) ? true : false}
                                            isValid={touched.ZipCode && !errors.ZipCode}
                                            isInvalid={touched.ZipCode && !!errors.ZipCode}
                                        />
                                        {touched.ZipCode && errors.ZipCode ? (
                                            <Form.Control.Feedback type="invalid">
                                                {errors.ZipCode}
                                            </Form.Control.Feedback>
                                        ) : ''}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='show_email_checkbox_blk'>
                            {['checkbox'].map((type) => (
                                <div key={`inline-${type}`} className="show_email_checkbox">
                                    <Form.Check
                                        inline
                                        label="Don’t have an account number handy or unable to find it?"
                                        name="emailSMS"
                                        type={type}
                                        id={`inline-${type}-1`}
                                        value="1"
                                        checked={(showKeyemailSMSinput) ? true : false}
                                        onChange={(e) => { handleChange; handleCheckType(e) }}
                                    />
                                </div>
                            ))}
                        </div>
                        {(showKeyemailSMSinput) &&
                            <>
                                <div className='contact_method_blk'>
                                    <h5 className='blk_title'>Please provide your registered email id or the registered phone number with SafeCircuits.</h5>
                                    {['radio'].map((type) => (
                                        <div key={`inline-${type}`} className="contact_method_radio">
                                            <Form.Check
                                                inline
                                                label="Email"
                                                name="contactMethod"
                                                type={type}
                                                id={`inline-${type}-1`}
                                                value="1"
                                                checked={(values.contactMethod == "1") ? true : false}
                                                onChange={(e) => { handleCheckinptType(e); handleChange; }}
                                            />
                                            <Form.Check
                                                inline
                                                label="Phone"
                                                name="contactMethod"
                                                type={type}
                                                id={`inline-${type}-2`}
                                                value="2"
                                                checked={(values.contactMethod == "2") ? true : false}
                                                onChange={(e) => { handleCheckinptType(e); handleChange; }}
                                            />
                                        </div>
                                    ))}
                                </div>

                                {(showEmailSMSinput == "1") &&
                                    <div className="form-group">
                                        <label htmlFor="inputEmail">Email</label>
                                        <Form.Control
                                            id="inputEmail"
                                            name="Email"
                                            type="email"
                                            onChange={handleChange}
                                            placeholder="Enter Email"
                                            value={values.Email}
                                            isValid={touched.Email && !errors.Email}
                                            isInvalid={touched.Email && !!errors.Email}
                                        />
                                        {touched.Email && errors.Email ? (
                                            <Form.Control.Feedback type="invalid">
                                                {errors.Email}
                                            </Form.Control.Feedback>
                                        ) : ''}
                                        <span className='small'>Based on the Email entered, we will send you an email with details of account that can be used to complete the Payment.</span>
                                    </div>
                                }

                                {(showEmailSMSinput == "2") &&
                                    <div className="form-group">
                                        <Form.Group>
                                            <label htmlFor="inputPhone">Phone Number</label>
                                            <InputMask
                                                mask="999-999-9999"
                                                placeholder="Phone Number"
                                                id="inputPhone"
                                                name="Phone"
                                                value={values.Phone}
                                                onChange={handleChange}
                                                isValid={touched.Phone && !errors.Phone}
                                                isInvalid={touched.Phone && !!errors.Phone}
                                            >
                                                {inputProps => <Form.Control {...inputProps} />}
                                            </InputMask>
                                            {touched.Phone && errors.Phone ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.Phone}
                                                </Form.Control.Feedback>
                                            ) : ''}
                                        </Form.Group>
                                        <span className='small'>Based on the Phone number entered, we will send you an SMS with details of account that can be used to complete the Payment.</span>
                                    </div>
                                }
                            </>
                        }
                        <div className='btn_blk'>
                            <Button variant="primary" className='btn red_btn' type="submit">Submit</Button>
                        </div>
                        <div className="alert alert-primary" role="alert">If you are unsure of the email or phone number to be entered, please call the Customer Service at <a href='tel:6319407595'>(631) 940-7595</a>.</div>
                    </form>
                </div>
                <Loader isLoading={isLoading} />
                <div className="copyright_blk">
                    <p>© {todayDate.getFullYear()} Safe Circuits. All Rights Reserved.</p>
                </div>
            </section>

            {/* Email/SMS Popup*/}
            <SuccessMsgMdl modalShow={modalShow} setModalShow={setModalShow} resp={resp} navigateToStep={navigateToStep} handleCloseSMSMdl={handleCloseSMSMdl} />

            {/* Sample Invoice Popup*/}
            <Modal size="md" className='invoice_popup' aria-labelledby="contained-modal-title-vcenter" show={sampleInvoiceModalShow} onHide={() => setSampleInvoiceModalShow(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Sample Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='sample_invoice'>
                        <img src='../img/sample-invoice.jpg' alt='Invoice' />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Step1;