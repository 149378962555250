const ENV = 'production'; // local | staging | production
var Data = {};
if (ENV == 'local' || ENV == 'staging') {
    Data = {
        TRUSTPILOT_API_URL: "https://api.trustpilot.com/v1/business-units/515214ce000064000524f576/reviews",
        TRUSTPILOT_API_KEY: "357NegaWF2pGs5a4Apk7CjajAFA7qyHV",
        TRUSTPILOT_PER_PAGE: 10,
        TRUSTPILOT_TAG_VALUE: "",
        POSTLEAD_API_URL: "https://qa2.slomins.com/QA/CloudData/2.1/CloudData.svc/PostLead",
        GOOGLE_ANALYTICS: false,
        GOOGLE_ANALYTICS_KEY: "UA-193271-3",
        GOOGLE_TAGMANAGER_KEY: "GTM-WNWLBBZ",
        GOOGLE_API_KEY: "AIzaSyCe0_xMPNIPaCnW1G1NAeujTVlCVh91x0o",
        SMARTLOOK_TRACKING: false,
        SMARTLOOK_TRACKING_KEY: "f8b59092b7f6d949db9322fd4ebe4305b17f75bb",
        AddPayment_Method_Api_Url: "https://qa2.slomins.com/QA/PaymentModule/1.0/PaymentModule/StoreCardInformation", 
        Appsid_Login_Url: "https://qa2.slomins.com/QA/PaymentModule/1.0/Account/Login",
        Appsid_Login_Username: "QPGMR",
        Appsid_Login_Password: "8D6BB9A4-9CAA-477C-B208-FC5B7E250342",
        GetAccountDetails_Api_Url: "https://qa2.slomins.com/QA/PaymentModule/1.0/PaymentModule/GetAccountDetails", 
        GetPaymentForm_Api_Url: "https://qa2.slomins.com/QA/PaymentModule/1.0/PaymentModule/GenerateToken", 
        Paymentech_js_Api_Url: "https://www.chasepaymentechhostedpay-var.com/hpf/js/hpfParent.min.js", 
        MakePayment_Api_Url: "https://qa2.slomins.com/QA/PaymentModule/1.0/PaymentModule/MakePayment",
        SendEmailSMS_Api_Url: "https://qa2.slomins.com/QA/PaymentModule/1.0/PaymentModule/SendEmailSMS",
        IsProd:false
    };
}  else if (ENV == 'preproduction') {
    Data = {
        TRUSTPILOT_API_URL: "https://api.trustpilot.com/v1/business-units/515214ce000064000524f576/reviews",
        TRUSTPILOT_API_KEY: "357NegaWF2pGs5a4Apk7CjajAFA7qyHV",
        TRUSTPILOT_PER_PAGE: 10,
        TRUSTPILOT_TAG_VALUE: "",
        POSTLEAD_API_URL: "https://qa2.slomins.com/QA/CloudData/2.1/CloudData.svc/PostLead",
        GOOGLE_ANALYTICS: false,
        GOOGLE_ANALYTICS_KEY: "UA-193271-3",
        GOOGLE_TAGMANAGER_KEY: "GTM-WNWLBBZ",
        GOOGLE_API_KEY: "AIzaSyCe0_xMPNIPaCnW1G1NAeujTVlCVh91x0o",
        SMARTLOOK_TRACKING: false,
        SMARTLOOK_TRACKING_KEY: "f8b59092b7f6d949db9322fd4ebe4305b17f75bb",
        AddPayment_Method_Api_Url: "https://qa2.slomins.com/QA/PaymentModule/1.0/PaymentModule/StoreCardInformation", 
        Appsid_Login_Url: "https://qa2.slomins.com/QA/PaymentModule/1.0/Account/Login",
        Appsid_Login_Username: "QPGMR",
        Appsid_Login_Password: "8D6BB9A4-9CAA-477C-B208-FC5B7E250342",
        GetAccountDetails_Api_Url: "https://qa2.slomins.com/QA/PaymentModule/1.0/PaymentModule/GetAccountDetails", 
        GetPaymentForm_Api_Url: "https://qa2.slomins.com/QA/PaymentModule/1.0/PaymentModule/GenerateToken", 
        Paymentech_js_Api_Url: "https://www.chasepaymentechhostedpay-var.com/hpf/js/hpfParent.min.js", 
        MakePayment_Api_Url: "https://qa2.slomins.com/QA/PaymentModule/1.0/PaymentModule/MakePayment",
        SendEmailSMS_Api_Url: "https://qa2.slomins.com/QA/PaymentModule/1.0/PaymentModule/SendEmailSMS",
        IsProd:false
    };
} else {
    Data = {
        TRUSTPILOT_API_URL: "https://api.trustpilot.com/v1/business-units/515214ce000064000524f576/reviews",
        TRUSTPILOT_API_KEY: "357NegaWF2pGs5a4Apk7CjajAFA7qyHV",
        TRUSTPILOT_PER_PAGE: 10,
        TRUSTPILOT_TAG_VALUE: "",
        POSTLEAD_API_URL: "https://svcs.slomins.com/PROD/CloudData/2.3/CloudData.svc/PostLead",
        GOOGLE_ANALYTICS: false,
        GOOGLE_ANALYTICS_KEY: "UA-193271-3",
        GOOGLE_TAGMANAGER_KEY: "GTM-WNWLBBZ",
        GOOGLE_API_KEY: "AIzaSyBihn12rLsamw8t-meUl8KYyTmR55Na4EI",
        SMARTLOOK_TRACKING: false,
        SMARTLOOK_TRACKING_KEY: "f8b59092b7f6d949db9322fd4ebe4305b17f75bb",
        AddPayment_Method_Api_Url: "https://svcs.slomins.com/PROD/PaymentModule/1.0/PaymentModule/StoreCardInformation", 
        Appsid_Login_Url: "https://svcs.slomins.com/PROD/PaymentModule/1.0/Account/Login",
        Appsid_Login_Username: "QPGMR",
        Appsid_Login_Password: "8D6BB9A4-9CAA-477C-B208-FC5B7E250342",
        GetAccountDetails_Api_Url: "https://svcs.slomins.com/PROD/PaymentModule/1.0/PaymentModule/GetAccountDetails",
        GetPaymentForm_Api_Url: "https://svcs.slomins.com/PROD/PaymentModule/1.0/PaymentModule/GenerateToken",
        Paymentech_js_Api_Url: "https://www.chasepaymentechhostedpay.com/hpf/js/hpfParent.min.js",
        MakePayment_Api_Url: "https://svcs.slomins.com/PROD/PaymentModule/1.0/PaymentModule/MakePayment",
        SendEmailSMS_Api_Url: "https://svcs.slomins.com/PROD/PaymentModule/1.0/PaymentModule/SendEmailSMS",
        IsProd:true
    };
}

export const constant: any = Data; 